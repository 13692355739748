import React, {useState} from "react";
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom"

import {Menu, MenuUser, Subtitle, Thumb} from './views/Generals'
import * as Bootstrap from "react-bootstrap"
import Home from './views/Home'
import Buscar from './views/Buscar'
import Recurso from './views/Recurso'
import Perfil from './views/Perfil'
import Login from './views/Login'
import Reset from './views/Reset'
import Logout from './views/Logout'
import Profile from './views/Profile'
import Register from './views/Register'
import MedicalPanel from "./views/MedicalPanel";
import Locations from "./views/Locations";
import EditMedicalProfile from "./views/EditMedicalProfile";
import CreateMedicalProfile from "./views/CreateMedicalProfile";

function Contentget(props){
  const [main,setMain]=useState(false)
  let domains=props.domains
  if(!main){
    let objecttosend={langid:domains.langid,hostname:domains.hostname}
    objecttosend=btoa(JSON.stringify(objecttosend))
    let url=domains.domainapi+'mainData/'+domains.apicode+'/'+objecttosend
    fetch(url).then(json=>json.text()).then(result=>{
      result=JSON.parse(result)
      if(result.error === 0 ){
        document.title = result.result.franch.metatitle_trans
        document.querySelector('meta[name="description"]').setAttribute("content", result.result.franch.metadescription_trans);
        document.querySelector('meta[name="keywords"]').setAttribute("content", result.result.franch.metakeyword_trans);
        document.querySelector('meta[name="title"]').setAttribute("content", result.result.franch.metatitle_trans);
        setMain(result.result)
      }else{
        console.error('result.message')
      }
    })
  }


  if(main !== null){
    return (<BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
            <Home showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
          </Structure>
        </Route>

        <Route exact path="/buscar">
          <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
            <Buscar showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
          </Structure>
        </Route>

        <Route exact path="/search">
          <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
            <Buscar showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
          </Structure>
        </Route>

        <Route exact path="/resources/:alias"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Recurso showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/recursos/:alias"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Recurso showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/identificate"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Login showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/login"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Login showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/crear-cuenta"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Register showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/create-account"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Register showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

        <Route exact path="/logout"
               render={ (props) =>
                   <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <Logout showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structure>} />

          <Route exact path="/reset"
                 render={ (props) =>
                     <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                         <Reset showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                     </Structure>} />



          <Route exact path="/recursos/:alias-:id">
            <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
              <Recurso showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
            </Structure>
          </Route>

          <Route exact path="/profile">
              <Structured showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                  <Profile showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
              </Structured>
          </Route>

          <Route exact path="/medical">
              <Structured showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                  <MedicalPanel showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
              </Structured>
          </Route>

          <Route exact path="/locations">
            <Structured showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
              <Locations showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} />
            </Structured>
          </Route>

          <Route exact path="/create-new">
            <Structured showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
              <CreateMedicalProfile setHeadingModal={props.setHeadingModal} setContentModal={props.setContentModal} showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains} />
            </Structured>
          </Route>

          <Route exact path="/editmedicalprofile"
               render={ (props) =>
                   <Structured showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                     <EditMedicalProfile showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                   </Structured>} />

          <Route exact path="/:alias"
                 render={ (props) =>
                     <Structure showModal={props.showModal} hideModal={props.hideModal} main={main} domains={domains}>
                         <Perfil showModal={props.showModal} hideModal={props.hideModal} main={main}  domains={domains} {...props}/>
                     </Structure>} />
      </Switch>
    </BrowserRouter>)
  }else{
    return(<div className={'py-5 text-center'}></div>)
  }
}

function Structure(props){
  let main=props.main
  let domains=props.domains
  return <div>{!main ? null:
      <div>
          <div className="bgcolor2">
            <Bootstrap.Container >
                <div className="row header fontd">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div className="logospace">
                          <a href="/" className="inline-block">
                            <img alt={main.admin.name} className="pc" height="38" src={domains.domain+main.admin.logonegative} />
                            <img alt={main.admin.name} style={{maxWidth: "35px"}} className="cell" src={domains.domain+main.admin.texture} />
                          </a>
                        </div>
                    </div>
                    <div className="col-xl col-lg col-md col-sm col align-right">
                      <Menu menu={main.menu} domains={domains}>
                        {domains.session !== null ?
                            <li key={'menuaccount'} className={"nav-item"}>
                              <Bootstrap.Dropdown>
                                <Bootstrap.Dropdown.Toggle variant="primary" id="dropdown-basic">
                                  <i className={'fa fa-user'}></i> Mi cuenta
                                </Bootstrap.Dropdown.Toggle>
                                <MenuUser/>
                              </Bootstrap.Dropdown>
                            </li> : null}
                      </Menu>
                    </div>
                </div>
            </Bootstrap.Container>
            </div>
          {props.children}
        <Footer main={main} domains={domains} />
      </div>}
  </div>
}

function Structured(props){
  let main=props.main
  let domains=props.domains
  let session=domains.session
  let medicalsession=domains.medicalsession
  let foto
  if(session.img !== null){
    foto = domains.domain+session.img
  }else{
    foto = domains.domain+'img/user.png'
  }

  let img
  if(medicalsession !== null){
    if(medicalsession.company_profile.texture !== null){
      img=domains.domain+''+medicalsession.company_profile.texture
    }else{
      img=domains.domain+'img/user.png'
    }

  }


  return <div >{!main ? null:
              <div id={"layout-wrapper"}>
                    <header id="page-topbar">
                          <div className="navbar-header">
                                <div className="d-flex">
                                      <div className="navbar-brand-box">
                                            <a href="/" className="logo logo-dark">
                                                <span className="logo-sm">
                                                    <img alt={main.admin.name} src={domains.domain+main.admin.texture} height="22" />
                                                </span>
                                                <span className="logo-lg">
                                                    <img alt={main.admin.name} src={domains.domain+main.admin.logo} height="29" />
                                                </span>
                                            </a>

                                            <a href="/" className="logo logo-light">
                                                <span className="logo-sm">
                                                    <img alt={main.admin.name} src={domains.domain + main.admin.texture} height="22"/>
                                                </span>
                                                <span className="logo-lg">
                                                    <img alt={main.admin.name} src={domains.domain + main.admin.logonegative} height="29"/>
                                                </span>
                                            </a>
                                      </div>

                                      <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                                          <i className="mdi mdi-menu"></i>
                                      </button>
                                      <div className="d-none d-lg-flex p-3">

                                      </div>
                                </div>

                                <div className="d-flex">
                                      <div className="dropdown d-none d-lg-inline-block">
                                          <button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                                            <i className="mdi mdi-fullscreen"></i>
                                          </button>
                                      </div>
                                      <div className="dropdown d-inline-block">
                                          <Bootstrap.Dropdown>
                                              <Bootstrap.Dropdown.Toggle variant={'light'} className={'mt-2'} id="dropdown-basic">
                                                  <img className="rounded-circle header-profile-user" src={foto}
                                                  alt={session.name} />
                                              </Bootstrap.Dropdown.Toggle>
                                              <MenuUser/>
                                          </Bootstrap.Dropdown>
                                      </div>
                              </div>
                          </div>
                    </header>
                    <div className="vertical-menu">
                        <div data-simplebar className="h-100">
                            <div id="sidebar-menu">
                              {medicalsession !== null ?
                                <div className={'text-center text-white'}>
                                  <Thumb img={img} size={130}/>
                                  <Subtitle className={'mt-3'} value={medicalsession.company_profile.name}/>
                                </div>
                              :null}
                              <div></div>

                                {medicalsession !==null ?
                                    <div>
                                      {medicalsession.company_users_type.generalconfig == true ?

                                            <ul className="metismenu list-unstyled" id="side-menu">
                                          <li>
                                              <a href="/editmedicalprofile">
                                                <i className="ti-pencil"></i>
                                                <span>Editar Perfil Médico</span>
                                              </a>
                                          </li>
                                          <li>
                                            <a href="/locations">
                                              <i className="ti-archive"></i>
                                              <span>Consultorios</span>
                                            </a>
                                          </li>

                                            </ul>
                                        : null}
                                    </div>
                                : null}

                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                              {!session.active ?
                                  <div className="alert alert-danger my-3"><i className="fas fa-exclamation-circle"></i> {main.translations.your_account_has_not_been_activated_yet}
                                  <a href={"/app/panel/resendactivation/"+session.id}
                                      className="alert-link">{main.translations.re_send_activation_link}</a></div>: null}

                              {props.children}
                            </div>
                        </div>
                        <footer className="footer position-fixed">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">© Intodoctor<span className="d-none d-sm-inline-block">.</span></div>
                                </div>
                            </div>
                        </footer>
                    </div>
              </div>}
      </div>
}

function Footer(props) {
  let main=props.main
  let domains=props.domains
  return (<footer className="py-3 bgcolor2">
    <div className="container">
      <nav className="nav flex-column">
        <div className="row">
          {main.franchises.map(item=>{
            return(
                <div key={'franchis'+item.id} className="col-12 col-md-4 col-lg-3"><a href={domains.protocol+'//www.'+item.dominio} className="nav-item nav-link text-left text-white" title={item.nombre}>{item.country.name}</a></div>
            )
          })}
        </div>
      </nav>
    </div>
  </footer>)
}

function App(){
  let protocol=window.location.protocol
  let path=window.location.pathname
  let hostname=window.location.hostname
  let hostnamemain=window.location.hostname
  let protocold=protocol
  if(hostname === 'localhost'){
    hostname='intodoctor.com'
    hostnamemain='localhost:3000'
    protocold='https:'
  }

  let sessiontext=sessionStorage.getItem('Auth')
  let session
  if(sessiontext === 'undefined'){
    session=null
  }else{
    session=JSON.parse(sessiontext)
  }

  let medicaltext=sessionStorage.getItem('Medical')
  let medicalsession
  if(medicaltext === undefined || medicaltext === null){
    medicalsession=null
  }else{
    medicalsession=JSON.parse(medicaltext)
  }

  let domainmain=protocold+'//'+hostname+'/app/'
  let domains = {
    domain:domainmain,
    domainapp:protocol+'//'+hostnamemain+'/',
    domainlinkre:'https://www.intodoctor.com/',
    hostname:hostname,
    protocol:protocol,
    domainmain:protocol+'//www.intodoctor.com/app/',
    domainapi:domainmain+'api/v1/',
    apicode:'kj23hflkds3984uldksj3498uasolk22asf',
    langid:1,
    currentpath:path,
    session:session,
    medicalsession:medicalsession
  }
  const [show, setShow] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [headingModal, setHeadingModal] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (<div>
    <Bootstrap.Modal show={show} onHide={handleClose}>
        <Bootstrap.Modal.Header closeButton>
            <Bootstrap.Modal.Title>{headingModal}</Bootstrap.Modal.Title>
        </Bootstrap.Modal.Header>
        <Bootstrap.Modal.Body>{contentModal}</Bootstrap.Modal.Body>
    </Bootstrap.Modal>
    <Contentget setHeadingModal={setHeadingModal} setContentModal={setContentModal} showModal={()=>handleShow()} hideModal={()=>handleClose()} domains={domains}/>
  </div>);
}


export default App;
