import React, {useState} from "react";
import {Loader} from "./Generals";
function Recurso(props) {
    let domains=props.domains
    let resourceid=props.match.params.alias

    const [resource,setResource]=useState(null)
    const goBack = () => {
        window.history.back();
    }

    const fetchResult = () => {
        let objecttosend={resourceid:resourceid,langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchResource/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0 ){
                setResource(result.result)
            }else{
                console.error('result.message')
            }
        })

    }

    if(resource == null){
        fetchResult()
    }

    return (
        <div className={'py-5'}>
            <div className={'container'}>
                {resource !== null ?
                    <div className={'row'}>
                        {resource.img !== null ? <div className={'col-12 col-md-6'}><img src={domains.domain+resource.img} alt={resource.title_translated}/></div> : null}
                        <div className={'col'}>
                            <h5>{resource.title_translated}</h5>
                            <div dangerouslySetInnerHTML={{__html:resource.headline_translated}}></div>
                            <div dangerouslySetInnerHTML={{__html:resource.description_translated}}></div>
                            <div dangerouslySetInnerHTML={{__html:resource.video_translated}}></div>
                            <div dangerouslySetInnerHTML={{__html:resource.resume_translated}}></div>
                            <button className={'btn btn-info'} onClick={()=>goBack()}>Volver</button>
                        </div>
                    </div>
                    : <div className={'text-center'}><Loader/></div>}
            </div>
        </div>
    );
}

export default Recurso;
