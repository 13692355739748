import React, {useState} from "react";
import {Loader, Moneyformat, Thumb, Subtitle, Searcher} from './Generals'
import * as Bootstrap from 'react-bootstrap'
function Buscar(props) {
    const urlParams = new URLSearchParams(window.location.search);
    let idtosearch=urlParams.get('idtosearch')
    let type=urlParams.get('type')
    let q=urlParams.get('q')
    let domains=props.domains
    const [page,setPage]=useState(1)
    const [onlyOnline,setOnlyOnline]=useState(false)
    const [pages,setPages]=useState(0)
    const [result,setResult]=useState(null)
    const toggleTypeService= () => {
        setResult(null)
        if(onlyOnline){
            setOnlyOnline(false)
        }else{
            setOnlyOnline(true)
        }
    }

    const fetchResultSearch = () => {
        let params = {idtosearch:idtosearch,langid:domains.langid,hostname:domains.hostname,page:page,type:type,onlineonly:onlyOnline}
        params=btoa(JSON.stringify(params))
        let url=domains.domainapi+'/fetchResults/'+domains.apicode+'/'+params

        fetch(url).then(json=>json.text()).then(result=>{
            result=JSON.parse(result)
            if(result.error === 0 ){
                if(type === "1"){
                    setPages(parseFloat(result.result.pages))
                    setResult(result.result)
                }else{
                    let url=domains.domainapp+result.result.profile.alias
                    window.location.href=url
                }
            }else{
                console.error(result.message)
            }
        })
    }

    const changePage = (page) => {
        setResult(null)
        setPage(page)
        setPages(0)
    }

    let pagination = []
    if(pages > 0){
        for (let pagecount=1; pagecount <= pages; pagecount++){
            pagination.push(<Bootstrap.Pagination.Item onClick = {()=>changePage(pagecount)}key={pagecount} active={pagecount === page}>{pagecount}</Bootstrap.Pagination.Item>,)
        }
    }

    if(result == null){
        fetchResultSearch()
    }


    return (
        <div className={'bggrey'}>
            <div className={'filters p-2 bg-white border-bottom'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-6 col-md-4 col-lg-3'}>
                            <label className={'text-sm small text-secondary'}>Por palabra</label>
                            <Searcher value={q} className={'border'} size={'sm'} domains={domains} main={props.main}/>
                        </div>
                        <div className={'col-6 col-md-4 col-lg-3'}>
                            <label className={'text-sm small text-secondary'}>Tipo de servicio</label>
                            <Bootstrap.Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Sólo consulta en línea"
                                onClick={()=>toggleTypeService()}
                                checked={onlyOnline ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={'container py-5'}>
                {result !== null ? <div>
                    <div>{result.result.length === 0 ? <div className={'alert alert-info'}>No hay resultados.</div> : null}</div>
                    {result.result.map(item=>{
                        console.log(item)
                        let img
                        if(item.company_profile.texture !== null){
                            img=domains.domain+item.company_profile.texture
                        }else{
                            img=domains.domain+'img/user.png'
                        }
                        return (
                            <div key={'resultdoc'+item.id} className={'row d-flex flex-column flex-md-row'}>
                                <div className={'col-md-12 result-column p-2 pr-3 pr-sm-2 pl-3'}>
                                    <div className={'card card-body'}>
                                        <div className="media">
                                            <Thumb className={'mr-4'} img={img} size={160}/>
                                            <div className="media-body">
                                                <a href={item.company_profile.alias}>
                                                    <Subtitle value={item.company_profile.name}/>
                                                </a>
                                                <p className="m-0 text-muted">
                                                    {item.company_profile.company_specialties_profile.map((items,index)=>{
                                                            return(<span><span dangerouslySetInnerHTML={{__html:items.company_specialty.nametranslated}}></span>
                                                                {index+1 < item.company_profile.company_specialties_profile.length ? <span>- </span>:null}</span>)
                                                        }
                                                    )}
                                                </p>
                                                {item.company_profile.claimed ? <span className={'badge badge-info'}>Perfil verificado</span> : null}
                                                {item.company_profile.online === true ? <span className={'badge badge-secondary mb-3'}><i className={'fa fa-video'}></i> {result.translations.online_consulting}</span> : null}
                                                <p className={'mt-3'}><a className={'text-secondary mt-4'} href={item.company_profile.alias}>Ver más</a></p>

                                                <ul className="nav nav-tabs mt-3" id={"pills-tab"+item.id} role="tablist">
                                                    {item.company_profile.company_locations.length > 0 ?
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active text-capitalize" id={"pills-home-tab"+item.id}
                                                           data-toggle="pill" href={"#pills-home"+item.id} role="tab"
                                                           aria-controls={"pills-home"+item.id} aria-selected="true">{result.translations.consultorios}</a>
                                                    </li>: null}
                                                    {item.company_profile.online === true ?
                                                        <li className="nav-item" role="presentation">
                                                            <a className="nav-link text-capitalize" id={"pills-profile-tab"+item.id}
                                                               data-toggle="pill" href={"#pills-profile"+item.id} role="tab"
                                                               aria-controls={"pills-profile"+item.id}
                                                               aria-selected="false">{result.translations.online}</a>
                                                        </li> : null}
                                                </ul>
                                                <div className="tab-content" id={"pills-tabContent"+item.id}>
                                                    {item.company_profile.company_locations.length > 0 ?
                                                    <div className="tab-pane card-body fade show active" id={"pills-home"+item.id}
                                                         role="tabpanel" aria-labelledby={"pills-home-tab"+item.id}>
                                                        {item.company_profile.company_locations.map(item=><p className={'text-secondary my-0'}><i className={'fa fa-map-marker'}></i> {item.detalle},{item.company_locations_establishment.address}, {item.company_locations_establishment.name}, {item.company_locations_establishment.country.name}</p>)}
                                                    </div>: null}
                                                    {item.company_profile.online === true ? <div className="tab-pane card-body fade" id={"pills-profile"+item.id}
                                                                                                 role="tabpanel" aria-labelledby={"pills-profile-tab"+item.id}>
                                                        <div className={'text-secondary'}>
                                                            <i className="fas fa-money-bill-alt"></i> {result.translations.online_consulting} - <Moneyformat value={item.company_profile.priceonline} symbol={item.company_profile.currency.symbol}/>
                                                        </div>
                                                    </div> : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {pages > 1 ? <div><Bootstrap.Pagination className={'mt-4'} size={'lg'}>{pagination}</Bootstrap.Pagination></div> : null}
                </div> : <div className={'text-center'}><Loader/></div>}
            </div>
        </div>
    );
}

export default Buscar;
