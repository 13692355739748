import React, {useState} from "react";
import {Loader, Input, ButtonRender,AlertRender} from './Generals'
import * as Bootstrap from 'react-bootstrap'
function Perfil(props) {
    let domains=props.domains
    let main=props.main
    let alias=props.match.params.alias
    let session=domains.session
    const [profile,setProfile]=useState(null)
    const [message,setMessage]=useState(null)
    const [error,setError]=useState({})
    const goBack = () => {
        window.history.back();
    }
    const [loading,setLoading]=useState(false)
    const fetchProfile = () => {
        let objecttosend={alias:alias,langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchProfile/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0 && result.result.error === 0){
                setProfile(result.result)
            }else{
                console.error('result.message')
                console.error('result.result.message')
            }
        })

    }

    let img
    if(profile == null){
        fetchProfile()
    }else{
        if(profile.profile.texture !== null){
            img=domains.domain+profile.profile.texture
        }else{
            img=domains.domain+'img/user.png'
        }
    }


    const submitContact = (claimed) => {
        setLoading(true)
        var formData = new FormData(document.querySelector('form#contact_form'))
        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })

        let objecttosend={langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))

        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url
        if(claimed){
            url=domains.domainapi+'submitContact/'+domains.apicode+'/'+objecttosend
        }else{
            url=domains.domainapi+'submitClaim/'+domains.apicode+'/'+objecttosend
        }
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                console.log(json)
                json=JSON.parse(json)
                if(json.error === 0){
                    if(claimed){
                        setMessage('Mensaje enviado.')
                    }else{
                        setMessage('Hemos recibido su solicitud. Estaremos contactándonos con usted para validar y continuar el proceso.')
                    }
                    setLgShow(false)
                }else{
                    setMessage('Error con el envío. Intente más tarde.')
                    setLgShow(false)
                }
                setLoading(false)
                setTimeout(()=>setMessage(null),5000)
            })

    }

    const validateFields = (claimed) =>{
        let empty=0;
        let errorobject={}
        let fields=document.getElementById("contact_form").elements;
        for(let count=0; count<=fields.length; count++){
            let item=fields[count]
            if(item !== undefined){
                let name=item.getAttribute('name')
                if(name !== null){
                    let value=item.value
                    if(value == ""){
                        empty++;
                        errorobject[name]=true
                    }else{
                        errorobject[name]=false
                    }
                }
            }
        }
        if(empty > 0){
            setError(errorobject)
        }else{
            submitContact(claimed)
        }
    }

    const [lgShow, setLgShow] = useState(false);
    return (
        <div className={'py-5 bggrey'}>
            <div className={'container'}>
                {profile !== null ?
                    <div className={'row'}>
                        <Bootstrap.Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Bootstrap.Modal.Header closeButton>
                                <Bootstrap.Modal.Title id="example-modal-sizes-title-lg">
                                    {profile.profile.claimed ? profile.translations.send_message : 'Reclamando propiedad de perfil'}
                                </Bootstrap.Modal.Title>
                            </Bootstrap.Modal.Header>
                            <Bootstrap.Modal.Body>
                                <form id={'contact_form'} method={'post'}>
                                    {session !== null ? <input type={'hidden'} value={session.id} name={'userid'}/> :null}
                                    <input type={'hidden'} value={profile.profile.id} name={'profileid'}/>
                                    <Input className={'mb-3'} error={error.name} label={main.translations.name} required={true} type={'text'} name={'name'} id={'name'}/>
                                    <Input className={'mb-3'} error={error.phone} label={main.translations.phone} required={true} type={'text'} name={'phone'} id={'phone'}/>
                                    <Input className={'mb-3'} error={error.email} label={main.translations.email} required={true} type={'email'} name={'email'} id={'email'}/>
                                    <Input className={'mb-3'} error={error.message} multiline={true} label={main.translations.message} required={true} type={'text'} name={'message'} id={'message'}/>
                                    {loading ? <Loader/> :<ButtonRender onClick={()=>validateFields(profile.profile.claimed)} size={'large'} variant={'contained'} color={'primary'} label={main.translations.send}/>}
                                </form>
                            </Bootstrap.Modal.Body>
                        </Bootstrap.Modal>

                        <div className={'col-12'}>
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-4 col-12 p-0">
                                        <div className="rounded-circle img-thumbnail p-1 mx-auto mt-3" style={{width:'265px', height:'265px'}}>
                                            <div className="bgimgv img-fluid avatar-lg rounded-circle" style={{backgroundImage: 'url('+img+')', width:'255px', height: '255px'}}></div>
                                        </div>
                                    </div>
                                    <div className="col text-md-left text-sm-center text-center">
                                        <div className={'p-3'}>
                                            {message !== null ? <div className={'mb-3'}><AlertRender type={'success'} value={message} /></div> : null}
                                            <h4 className="m-0 text-muted">{profile.profile.name}</h4>
                                            {!profile.profile.claimed ? <p className={'text-secondary'}><small><a className={'text-link'} onClick={() => setLgShow(true)}>Eres tu? Reclama este perfil</a></small></p> : <span className={'badge badge-info'}>Perfil verificado</span>}
                                            <p className="m-0 text-muted">
                                                {profile.specialties.map((item,index)=>{
                                                    return(<span><span dangerouslySetInnerHTML={{__html:item.nametranslated}}></span>{index+1 < profile.specialties.length ? <span>- </span>:null}</span>)
                                                }
                                                )}
                                            </p>
                                            {profile.profile.doctorcountrynumber !== null && profile.profile.doctorcountrynumber !== "" ?<p className="m-0 text-muted"><small>{profile.translations.no_de_colegiado} {profile.profile.doctorcountrynumber}</small></p>:null}
                                            {profile.profile.claimed ? <p className="mt-3"><a className="text-success" onClick={() => setLgShow(true)}><i className="fas fa-envelope-open-text"></i> {profile.translations.send_message}</a></p> : null}
                                            <ul className="nav nav-pills mt-3 pc">
                                                <li className="nav-item d-lg-block"><a className="nav-link btn-secondary text-capitalize" href="#experience">{profile.translations.experience}</a></li>
                                                <li className="nav-item"><a className="nav-link btn-secondary text-capitalize ml-2" href="#locations">{profile.translations.locations}</a></li>
                                                <li className="nav-item"><a className="nav-link btn-secondary text-capitalize ml-2" href="#services">{profile.translations.services}</a></li>
                                                {profile.insurances.lenght > 0 ?
                                                    <li className="nav-item"><a className="nav-link btn-secondary ml-2"
                                                                                href="#insurance">{profile.translations.insurances}</a></li> : null}
                                                <li className="nav-item"><a href="#" className={'nav-link btn-secondary ml-2'} onClick={()=>goBack()}>Volver</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-3 pc">
                                <div id="experience" className="card-header text-capitalize">{profile.translations.experience}</div>
                                <div className="card-body text-muted">
                                    <div className="row">
                                        <div className="col-lg-1">
                                            <i className="fas text-muted fa-address-card"></i>
                                        </div>
                                        <div className="col">
                                            <p className="text-muted">{profile.translations.about}</p>
                                            {profile.profile.description}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col"><hr/></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-1">
                                            <i className="fas text-muted fa-star-of-life"></i>
                                        </div>
                                        <div className="col">
                                            <p className="text-muted">{profile.translations.specialties}</p>
                                            <ul className="list-inline">
                                                {profile.specialties.map(item=> <li className="list-inline-item"><span
                                                    className="badge badge-primary">{item.nametranslated}</span></li>)}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {profile.locations.length > 0 ?
                                <div id="locations" className="card mt-3 pc">
                                    <div className="card-header">{profile.translations.locations}</div>
                                    <div className="card-body">
                                        <h5 className="text-muted m-0"></h5>
                                        {profile.locations.map(item=> <div className="row">
                                            <div className="col-xl-1">
                                                <i className="fas text-muted fa-map-marker-alt"></i>
                                            </div>
                                            <div className="col">
                                                <h6 className="color2">{item.name}</h6>
                                                <p className="color2">{item.address}</p>
                                                <p className="phone m-0 text-muted">{item.phone}</p>
                                                <p className="m-0 text-muted">{item.schedule}</p>
                                            </div>
                                            <div className="col-12">
                                                <hr/>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            : null}
                            {profile.products.length > 0 ?
                            <div id="services" className="card mt-3 pc">
                                <div className="card-header">{profile.translations.products_and_services}</div>
                                <div className="card-body">
                                    <h5 className="text-muted m-0"></h5>
                                    {profile.products.map(item=>
                                    <div className="row">
                                        <div className="col-xl-1">
                                            <i className="fas text-success fa-check-circle "></i>
                                        </div>

                                        <div className="col">
                                            <h6 className="color2">{item.name}</h6>
                                        </div>
                                        <div className="col-12">
                                            <hr/>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            : null }
                            {profile.insurances.length > 0 ?
                                <div id="insurance" class="card mt-3 pc">
                                    <div className="card-header">Seguros</div>
                                    <div className="card-body">
                                        <h5 className="text-muted m-0"></h5>
                                        {profile.insurances.map(item=>
                                        <div className="row">
                                            <div className="col-xl-1">
                                                <i className="fas text-muted fa-id-card-alt"></i>
                                            </div>

                                            <div className="col">
                                                <h6 className="color2">{item.components_seguro.name}</h6>
                                            </div>
                                            <div className="col-12">
                                                <hr/>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            :null}
                        </div>
                    </div>
                    : <div className={'text-center'}><Loader/></div>}
            </div>
        </div>
    )
}

export default Perfil;
