import React, {useState} from "react";
import {Loader, Title} from './Generals'
function Reset(props) {
    let domains=props.domains
    let main=props.main
    const [email,setEmail] = useState(null)
    const [loading,setLoading] = useState(false)
    const [message,setMessage] = useState(null)
    const [isEmpty,setIsEmpty] = useState(null)
    const sendReset = (values) => {
        let field
        values=btoa(JSON.stringify(values))
        let url=domains.domainapi+'reset/'+domains.apicode+'/'+values
        console.log(url)
        fetch(url,{
            credentials: 'include'
        }).then(response=>response.json()
        ).then((json)=>{
            if(json.result.error === 0){

                setLoading(false)
                setMessage(json.result.message)
            }else{
                setLoading(false)
                setMessage(json.result.message)
            }

        })
    }

    const validateReset = (value) => {
        let missing = 0
        if(email === "" || email === null){
            setIsEmpty(true)
            missing++
        }else{
            setIsEmpty(false)
        }

        if(missing === 0){
            setLoading(true)
            let values= {
                email:email
            }
            sendReset(values)
        }

    }


    return (
        <div className={'py-5 bggrey h-100'}>
            <div className="h-100">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center my-5">
                        <div className="col-lg-6 col-md-6 col-sm-7 col-xs-12 h-100 table-cell text-center">
                            <Title className={'mb-4'} value={main.translations.reset_password}/>
                            <div className="card">
                                <div className="card-body text-center">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                {message !== null ? <div className={'alert alert-warning py-1 mt-2 validatespaceemail'}>{message}</div> : null}
                                                <label>{main.translations.email}<i
                                                    className="fa fa-asterisk red"></i></label>
                                                <input onChange={(e)=>setEmail(e.target.value)}
                                                    className="form-control form-control-lg input-lg margin-bottom-small"
                                                    type="text" required="required" name="username" id="username"/>
                                                {isEmpty ? <div className={'validatespace alert alert-warning py-1 mt-2 validatespaceemail'}>Este campo no puede estar vacío.</div> : null}
                                            </div>
                                        </div>
                                    {loading ? <Loader/> :
                                        <div>
                                            <button onClick={()=>{validateReset()}} type={'button'}
                                               className="btn inline-block bgcolor1 text-white btn-block btn-lg input-lg margin-top-small"
                                            >{main.translations.reset_password}</button>

                                            <p className="mt-3 text-center">
                                                <a className="color1" href="/login">{main.translations.log_in}</a>
                                            </p>
                                        </div>
                                         }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reset;
