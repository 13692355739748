import React, {useState} from "react";
import * as Bootstrap from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Warning from '@material-ui/icons/Warning';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        }
    }
}))

export function AlertRender(props) {

    return (
        <div>
            <Alert severity={props.type}>{props.value}</Alert>
        </div>
    );
}

export function Tags(props) {
    const fixedOptions = [];
    let values=props.value
    let data=props.data
    const [value, setValue] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [loadedd, setLoadedd] = useState(false)
    if(values !== undefined){
        let fixed=data.map(item=>{
            values.map(itemd=>{
                if(item.id === itemd.specialtiesid){
                    fixedOptions.push(item)
                }
            })
        })
        Promise.all(fixed).then(()=>setLoaded(true))
    }else{
        if(!loaded){
            setLoaded(true)
        }
    }


    if(loaded && !loadedd){
        setLoadedd(true)
        setValue([...fixedOptions])
    }

    return <div>{loaded && loadedd ?
        <div>

            <Autocomplete
                multiple
                id={props.name}
                options={props.data}
                getOptionLabel={(option) => option.name}
                defaultValue={value}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        placeholder={props.placeholder}
                    />
                )}
            />
            {value !== null ? <div>{value.map((item,index)=><input type={'hidden'}
                                           value={item.id}
                                           id={props.name+item.id}
                                           required={props.required}
                                           name={props.name+'['+index+']'}/>)}</div> : null}

        </div> : <Loader/>}</div>

}

export function MenuUser(props) {
    return(
        <Bootstrap.Dropdown.Menu>
            <Bootstrap.Dropdown.Item className={'text-secondary'} href="/profile">Mi Perfil</Bootstrap.Dropdown.Item>
            <Bootstrap.Dropdown.Item className={'text-secondary'} href="/medical">Panel Médico</Bootstrap.Dropdown.Item>
            <Bootstrap.Dropdown.Divider />
            <Bootstrap.Dropdown.Item className={'text-secondary'} href="/logout">Salir</Bootstrap.Dropdown.Item>
        </Bootstrap.Dropdown.Menu>
    )
}

export function ButtonRender(props) {
    return <div><Button {...props}>{props.label}</Button></div>
}
export function Select(props) {
    const classes = useStyles();
    let data=props.data
    const [value,setValue]=useState(null)
    const [valueid,setValueid]=useState(null)
    const [loaded,setLoaded]=useState(false)

    if(!loaded && props.value !== undefined && props.value !== null){
        let allselected=null
        let promise=data.map(item=>{
            if(item.id == props.value){
                allselected=item
            }
        })
        Promise.all(promise).then(()=>{
            setValue(allselected)
            setValueid(allselected.id)
            setLoaded(true)
        })
    }

    let error = false
    if(props.error !== undefined){
        error=props.error
    }


    return (
        <div {...props}>
        <Autocomplete
            style={{ width: '100%' }}
            options={data}
            classes={{
                option: classes.option,
            }}
            onChange={(event, newValue) => {
                setValue(newValue);
                setValueid(newValue.id)
            }}
            value={value}
            defaultValue={value}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
                <React.Fragment>
                    {option.name}
                </React.Fragment>
            )}
            renderInput={(params) => {
                return(
                <TextField name={props.name}
                           className={'bg-white'}
                    {...params}
                    label={props.label}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}}
        />
            {error ? <div className={'alert alert-warning text-left alert-sm mt-1 py-1'}><Warning className={'mr-2 float-left'}/><small>Tiene que seleccionar alguna opción.</small></div> : null}
        <input type={'hidden'}
               value={valueid}
               id={props.id}
               required={props.required}
               name={props.name}/>
        </div>
    )
}


export function DatePicker(props) {
    let val=new Date(props.defaultValue)
    val=moment(val).utc().format('YYYY-MM-DD')
    const [selectedDate, setSelectedDate] = useState(val);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    let error = false
    if(props.error !== undefined){
        error=props.error
    }

    let required = false
    if(props.required !== undefined && props.required){
        required=true
    }
    return (<div {...props}>
        <div><div >
        <TextField
            name={props.name}
            id={props.id}
            label={props.label}
            type="date"
            required={required}
            defaultValue={val.toString()}
            variant={'outlined'}
            style={{
                width: '100%',
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
        {error ? <div className={'alert alert-warning text-left alert-sm mt-1 py-1'}><Warning className={'mr-2 float-left'}/><small>Tiene que seleccionar alguna opción.</small></div> : null}
        </div></div></div>);
}

export function Numberformat(props) {
    return (<span>{new Intl.NumberFormat().format(props.value)}</span>)
}

export function Moneyformat(props) {
    return (<span><small>{props.symbol}</small><Numberformat value={props.value}/></span>)
}

export function toURI(value) {
    value=value.trim()
    let array=["¿","?",":",",",'ó','á','é','í','ú']
    value=value.replace(/\s+/g, '-').toLowerCase()
    for (let i =0; i< array.length; i++){
        let toreaplace=array[i]
        let newval
        if(toreaplace === ('ó') || toreaplace === ('á') || toreaplace === ('é') || toreaplace === ('í') || toreaplace ===('ú')){
            if(toreaplace === ('ó') ){
                newval='o'
            }else if(toreaplace === ('á') ){
                newval='a'
            }else if(toreaplace === ('é') ){
                newval='e'
            }else if(toreaplace === ('í') ){
                newval='i'
            }else if(toreaplace === ('ú') ){
                newval='u'
            }
        }else{
            newval=''
        }
        value=value.replaceAll(toreaplace, newval)
    }
    return value
}

export function Title(props) {
    return (<div {...props}><h3 className={'text-secondary font-weight-light'}>{props.value}</h3></div>)
}

export function Searcher(props) {
    let domains = props.domains
    let main = props.main
    const [idspecialty,setIdspecialty]=useState(false)
    const [type,setType]=useState(false)
    const [specialties,setSpecialties]=useState(null)
    const [showDropdown,setShowDropdown]=useState(false)
    const [showToolTip,setShowToolTip]=useState(false)
    const [keyword,setKeyword]=useState(null)
    const selectOnClick = (item,type) => {
        let val
        if(type === 1){
            val=item.details.name_translated
        }else{
            val=item.details.name
        }
        document.getElementById('specialtiesvalue').value=val
        setIdspecialty(item.details.id)
        setType(type)
        setShowDropdown(false)
    }

    const search = (spcid,type) => {
        let val=document.getElementById('specialtiesvalue').value
        let formData = new FormData();
        formData.append('q',val)
        formData.append('idtosearch',spcid)
        formData.append('type',type)
        let asString = new URLSearchParams(formData).toString();
        let url=domains.domainapp+toURI(main.translations.search)+'?'+asString
        window.location.href=url
    }

    const fetchResult = (e) => {
        setShowDropdown(false)
        if (e.keyCode === 13) {
            if(specialties !== null){
                let val
                if(specialties.specialties[0] !== undefined){
                    val=specialties.specialties[0].details.name_translated
                }else if(specialties.accounts[0] !== undefined){
                    val=specialties.accounts[0].details.name
                }
                document.getElementById('specialtiesvalue').value=val
                let selected
                let typeval
                if(specialties.specialties.length > 0){
                    typeval=1
                    selected=specialties.specialties[0].details.id
                    setIdspecialty(selected)
                    setType(typeval)

                }else{
                    typeval=2
                    selected=specialties.accounts[0].details.id
                    setIdspecialty(selected)
                    setType(typeval)
                }
                search(selected,typeval)
            }
        }else{
            setShowDropdown(true)
            setIdspecialty(false)
            setType(false)
            let value=e.target.value
            if(value.length > 1){
                setKeyword(value)
                let objecttosend={value:value,langid:domains.langid,hostname:domains.hostname}
                objecttosend=btoa(JSON.stringify(objecttosend))
                let url=domains.domainapi+'fetchSpecialties/'+domains.apicode+'/'+objecttosend
                fetch(url).then(json=>json.json()).then(result=>{
                    if(result.error === 0 ){
                        setShowDropdown(true)
                        setSpecialties(result.result)
                    }else{
                        console.error('result.message')
                    }
                })
            }
        }
    }
    return(
        <div {...props}>
            <div className={'autocompleteholder position-relative'}>
                <div className="input-group">
                    <input id={'specialtiesvalue'} defaultValue={props.value !== undefined ? props.value : null} onKeyUp={(e)=>fetchResult(e)} name={'especialidad'} className={'form-control form-control-'+props.size+' border-0 specialtyselect'} />
                    {props.showButton ? <div className="input-group-append">
                        {!idspecialty ?
                            <div className={'position-relative'}>
                                <div style={{width:120,top:'-65px'}} className={showToolTip ? 'bs-tooltip-top position-absolute fade show tooltip' : 'bs-tooltip-top position-absolute fade tooltip'} role={'tooltip'} ><div className={'tooltip-inner'}><span className={'arrow'}></span>Seleccione antes de buscar</div></div>
                                <button onClick={()=>setShowToolTip(true)} className={"btn rounded-0 btn-"+props.size+" btn-light border-left tooltiptoogle"} data-toggle="tooltip" data-placement="top" title="Tooltip on top">{main.translations.search}</button>
                            </div>
                            :<button onClick={()=>search(idspecialty,type)} className="btn btn-light border-left">{main.translations.search}</button>}
                    </div>: null}
                </div>
                {specialties !== null && showDropdown && (specialties.specialties.length > 0 || specialties.accounts.length > 0) ?
                    <div id={'autocompletespace'} className={'shadow autocomplete z-index99 bg-white p-1 position-absolute rounded list-group'}>
                        {specialties.specialties.length > 0 ? <div>
                            <p className={'text-secondary font-sm small mb-0 p-2'}><i className={'fa fa-glasses mr-2'}></i>{main.translations.specialties}</p>{specialties.specialties.map(item=>{
                            let nametodisplay=item.details.name_translated
                            let substr = keyword
                            nametodisplay=nametodisplay.replace(substr,'<strong class="color1">'+substr+'</strong>')
                            return (<p className={'text-secondary list-group-item border-0 p-2 list-group-item-action'} onClick={()=>selectOnClick(item,1)} key={'list'+item.details.id}><span dangerouslySetInnerHTML={{__html:nametodisplay}}></span></p>)
                        })}</div> : null}

                        {specialties.accounts.length > 0 ? <div>
                            <p className={'text-secondary font-sm small mb-0 p-2 border-bottom border-style-dotted'}><i className={'fa fa-user-md mr-2'}></i>Doctores</p>{specialties.accounts.map(item=>{
                            let nametodisplay=item.details.name
                            let substr = keyword
                            nametodisplay=nametodisplay.replace(substr,'<strong class="color1">'+substr+'</strong>')
                            return (<p className={'text-secondary list-group-item border-0 p-2 list-group-item-action'} onClick={()=>selectOnClick(item,2)} key={'list'+item.details.id}><span dangerouslySetInnerHTML={{__html:nametodisplay}}></span></p>)
                        })}</div> : null}

                    </div> : null}
            </div>
        </div>
    )
}

export function Jumbotitle(props) {
    return (<div {...props}><h1 className={'font-weight-bold'}>{props.value}</h1></div>)
}

export function Subtitle(props) {
    return (<div {...props}><h5 className={'font-weight-light'}>{props.value}</h5></div>)
}

export function Thumb(props) {
    return(<div {...props}><div className="rounded-circle img-thumbnail p-1 mx-auto" style={{width:props.size+'px', height:props.size+'px'}}>
        <div className="bgimgv img-fluid avatar-lg rounded-circle" style={{backgroundImage: 'url('+props.img+')', width:(props.size-10)+'px', height: (props.size-10)+'px'}}></div>
    </div></div>)

}

export function Thumbsquare(props) {
    return(<div {...props}><div className="img-thumbnail p-1 mx-auto" style={{width:props.size+'px'}}>
        <div className="bgimgv im¨-fluid avatar-lg rounded-circle" style={{backgroundImage: 'url('+props.img+')', width:(props.size-10)+'px'}}></div>
    </div></div>)
}

export function Input(props) {
    let classes = {root: {
            width: '100%',
        }}

    let error = false
    if(props.error !== undefined){
        error=props.error
    }

    let required = false
    if(props.required !== undefined && props.required){
        required=true
    }

    let readonly = false
    if(props.readonly !== undefined && props.readonly){
        readonly=true
    }

    let propstopass={}
    let multiline = false
    if(props.multiline !== undefined && props.multiline){
        multiline=true
    }

    let onChange = () => null
    if(props.onChange !== undefined && !props.onChange){
        onChange=props.onChange
    }

    let onBlur = () => {return null}
    if(props.onBlur !== undefined && !props.onBlur){
        onBlur=props.onBlur
    }

    return <div><div className={props.className}>
        <FormControl style={classes.root} >
            {required ? <TextField multiline={multiline} type={props.type} id={props.id} onChange={(e)=>onChange(e)} onBlur={(e)=>onBlur(e)} required variant={'outlined'}  error={error} label={props.label} name={props.name}
                                   InputProps={{
                                       readOnly: readonly,
                                   }} defaultValue={props.defaultValue}/>
            : <TextField id={props.id} multiline={multiline} type={props.type} variant={'outlined'} error={error}
                         InputProps={{
                             readOnly: readonly,
                         }}
                         onBlur={(e)=>onBlur(e)} onChange={(e)=>onChange(e)} label={props.label} name={props.name} readOnly={props.readonly} defaultValue={props.defaultValue}/>}
            <FormHelperText id="my-helper-text">{props.comment}</FormHelperText>
        </FormControl>
    </div></div>

}


export function Menu(props) {
    let menu=props.menu
    let domains=props.domains
    return(
        <ul className="nav nav-pills justify-content-end">
            {menu.map(item=>{
                let currentpath=domains.currentpath
                let classval =""
                let link
                if(item.alias_translated === "" || item.alias_translated === "/"){
                    item.alias_translated='/'
                    link=item.alias_translated
                }else{
                    link=domains.domainapp+item.alias_translated
                }
                if(currentpath === item.alias_translated){
                    classval='active'
                }
                if(link !== '/'){
                    if(item.login === 2 || (domains.session == null && item.login === 0) || (domains.session !== null && item.login === 1)){
                        return(<li key={'menu'+item.id} className={"nav-item "+classval}><a target="_self" id="" className={"nav-link "+classval} href={link}>{item.name_translated}</a></li>)
                    }else{
                        return null
                    }
                }else{
                    return null
                }
            })}
            {props.children}
        </ul>
    )
}

export function Loader() {
    return(
        <div className="loadingio-spinner-pulse-f4brtrr4ohh">
            <div className="ldio-0r2lh8q4cnwl">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}