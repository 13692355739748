import React, {useState,useEffect} from "react";
import {Input, Loader, Select, Thumbsquare, Title, Thumb, ButtonRender, Subtitle, Tags} from './Generals'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/Check';
import Info from '@material-ui/icons/Info';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

function CreateMedicalProfile(props) {
    console.log(props)
    let domains=props.domains
    let main=props.main
    let session=domains.session
    let [loading,setLoading]=useState(false)
    let [loadedContent,setLoadedContent]=useState(false)
    const [online,setOnline]=useState(false)
    const [validatedZoom,setValidatedZoom]=useState(false)
    const [apiKeyZoom,setApiKeyZoom]=useState(null)
    const [apiSecretZoom,setApiSecretZoom]=useState(null)
    const [apiChatZoom,setApiChatZoom]=useState(null)
    const [message,setMessage]=useState(false)
    const [messaged,setMessaged]=useState(false)
    let [loadingValidation,setLoadingValidation]=useState(false)
    let [alias,setAlias]=useState(null)
    let showModal=props.showModal
    let setContentModal=props.setContentModal
    let setHeadingModal=props.setHeadingModal
    const handletUpdateValue = (e,name) => {
        let value=e.target.value
        if(name == 'apiKey'){
            setApiKeyZoom(value)
        }
        if(name == 'apiSecret'){
            setApiSecretZoom(value)
        }
        if(name == 'apiChat'){
            setApiChatZoom(value)
        }
        setValidatedZoom(false)
    }

    const fetchProfile = () => {
        let objecttosend={langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchCreateMedicalData/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0){
                setLoadedContent(result.result)
            }else{
                console.error('result.message')
            }
        })
    }

    if(!loadedContent){
        fetchProfile()
    }

    const validateZoom = () => {
        setLoadingValidation(true)
        let objecttosend = {key: apiKeyZoom, secret: apiSecretZoom, chat: apiChatZoom}
        objecttosend = btoa(JSON.stringify(objecttosend))
        let url = domains.domainapi + 'getToken/' + domains.apicode + '/' + objecttosend
        fetch(url).then(json => json.json()).then(result => {
            if (result.error === 0) {
                let token = result.result
                if(token.response !== undefined && token.response.code !== undefined){
                    setMessage(<div className={'alert alert-danger'}><Warning className={'mr-2'}/>{token.response.message}</div>)
                    setValidatedZoom(false)
                }else{
                    setMessage(<div className={'alert alert-success'}><Check className={'mr-2'}/>Los códigos del API han sido validados</div>);
                    setValidatedZoom(true)
                    setTimeout(()=>setMessage(false),2000)
                }
                setLoadingValidation(false)
            }
        })
    }

    const submitChanges = () => {
        setLoading(true)
        var formData = new FormData(document.querySelector('form#submitchanges'))
        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })

        let objecttosend={model:'CompanyProfile',userid:session.id}
        objecttosend=btoa(JSON.stringify(objecttosend))

        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url=domains.domainapi+'submitNew/'+domains.apicode+'/'+objecttosend
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === 0){
                    window.location.href = '/medical'
                }else{
                    console.log(json)
                }
            })

    }

    const openInfo = () => {
        setContentModal('Puede ser llamado Exequátur, número de colegiatura, colegiado o permiso, dependiendo del país donde se encuentre.')
        setHeadingModal('¿Qué es esto?')
        showModal()
    }

    const validatetoAlias = (alias) => {
        let objecttosendd={name:alias}
        objecttosendd=btoa(JSON.stringify(objecttosendd))
        let urld=domains.domainapi+'proposealias/'+domains.apicode+'/'+objecttosendd
        fetch(urld).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                console.log(json)
                if(json.error === 0){
                    setAlias(json.result.alias)
                    if(json.result.other === 1){
                        setMessaged('Este usuario ya está en uso, pero le proponemos este.')
                    }else{
                        setMessaged(false)
                    }
                    setDisable(false)
                }else{
                    console.log(json)
                }
            })

    }

    const handleChange = () => {
        if(online){
            setOnline(false)
        }else{
            setOnline(true)
        }
    }
    const [disable,setDisable] = useState(false)

    const changeAlias = (e) => {
        setDisable(true)
        let value=e.target.value
        validatetoAlias(value)
    }

    const validateChangeName =(e)=>{
        if(alias == null){
            setDisable(true)
            let name=e.target.value
            validatetoAlias(name)
        }
    }

    return (<div>
        <div className={'py-4'}>
            { loadedContent ?
<div>
                <div className={'text-center'}>
                    <Title value={'Creando nueva cuenta de doctor.'} className={'mb-4'}/>
                </div>
            <div className={'card card-body mt-3'}>
                <div className={'text-center'}>
                    <Title value={loadedContent.translations.create_medical_profile} className={'mb-4'} />
                </div>
                <form id={'submitchanges'} >
                    <div className={'row'}>
                        <div className={'col'}>
                            <FormControl className={'mb-3'} style={{width:'100%'}}>
                                <TextField onBlur={(e)=>validateChangeName(e)} label={main.translations.name} type={'text'} id={'name'} required variant={'outlined'} name={'name'} />
                            </FormControl>
                            {messaged ? <div className={'alert alert-warning p-2'}><small>{messaged}</small></div>:null}
                            <label>Alias</label>
                            {!disable ? <input disabled={disable} onBlur={(e)=>changeAlias(e)} className={'form-control form-control-lg'} placeholder={'Alias'} defaultValue={alias}/> : <div><Loader/></div>}
                            <FormHelperText className={'mb-3'} id="my-helper-text">Este valor se utilizará como dirección para acceder a su perfil. Ej: intodoctor.com/usuario</FormHelperText>


                            <Input type={'text'} name={'doctorcountrynumber'} required={true} label={loadedContent.translations.tuition_number} />
                            <FormHelperText className={'mb-3'} id="my-helper-text">Si este valor no ha sido verificado, no saldrá en los resultados. <a onClick={openInfo} className={'text-primary'}><Info/></a></FormHelperText>

                            <Input type={'text'} name={'whatsapp'} className={'mb-3'} required={true} label={loadedContent.translations.cell_phone} />
                            <Select name={'countryid'} label={main.translations.select_country} className={'mb-3'} data={loadedContent.countries} />
                            <Select name={'langid'} label={main.translations.select_language} className={'mb-3'} data={loadedContent.languages} />
                            <Select name={'main_specialty'} required={true} label={loadedContent.translations.principal_specialty} className={'mb-3'} data={loadedContent.specialties} />
                            <Tags name={'secondary_specialties'} data={loadedContent.specialties} label={loadedContent.translations.secondary_specialty}/>
                            <FormControlLabel
                                control={<Switch checked={online} onChange={handleChange} />}
                                label={loadedContent.translations.this_account_offers_online_consulting}
                            />

                            <input name="online" type={'hidden'} value={online} />
                            {online ? <div className={'card card-body bg-light mb-3'}>
                                        <p>Hacemos las reuniones utilizando Zoom. Cada cuenta de doctor, debe de tener una cuenta Zoom creada y colocar los siguientes datos: </p>
                                        <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiKey')} name={'zoom_api_key'} className={'mb-3 bg-white'} required={true} label={'Zoom API Key'} />
                                        <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiSecret')} name={'zoom_api_secret'} className={'mb-3 bg-white'} required={true} label={'Zoom API Secret'} />
                                        <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiChat')} name={'zoom_im_chat_history_token'} className={'mb-3 bg-white'} required={true} label={'Zoom IM chat history Token'} />
                                        <input name="validatedZoom" type={'hidden'} value={validatedZoom} />
                                        {loadingValidation ? <Loader/> : null}
                                        {message ? <div>{message}</div>:null}
                                        {!validatedZoom ? <div><p><small>Cada vez que haga un cambio a los datos del API de Zoom, debe validarlo clicando en el siguiente botón.</small></p>
                                        <ButtonRender size="large" color={'secondary'} onClick={()=>validateZoom()} variant={'contained'} label={main.translations.validate}/></div> :null}
                                    </div> : null }
                            {!loading ? <div>
                                {(online && validatedZoom) || !online ? <ButtonRender size="large" color={'primary'} onClick={()=>submitChanges()} variant={'contained'} label={main.translations.save}/> : <ButtonRender size="large" color={'primary'} onClick={()=>submitChanges()} disabled={true} variant={'contained'} label={main.translations.save}/>}
                            </div> : <Loader/>}
                        </div>
                    </div>
                </form>

            </div></div>:<Loader/>}
        </div>
        </div>
    )
}

export default CreateMedicalProfile;
