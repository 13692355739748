import React, {useState} from "react";
import {Input, Loader, Select, Thumbsquare, Title, Thumb, ButtonRender, Subtitle,Tags} from './Generals'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from "@material-ui/core/FormHelperText";

function Profile(props) {
    let domains=props.domains
    let medicalsession=domains.medicalsession
    let profileid=medicalsession.company_profile.id
    let main=props.main
    let [loading,setLoading]=useState(false)
    const [loadedContent,setLoadedContent]=useState(false)
    const [online,setOnline]=useState(false)
    const [validatedZoom,setValidatedZoom]=useState(false)
    const [apiKeyZoom,setApiKeyZoom]=useState(null)
    const [apiSecretZoom,setApiSecretZoom]=useState(null)
    const [apiChatZoom,setApiChatZoom]=useState(null)
    const [message,setMessage]=useState(false)
    let [loadingValidation,setLoadingValidation]=useState(false)

    const fetchProfile = () => {
        let objecttosend={langid:domains.langid,profileid:profileid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchEditMedicalData/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0){
                setOnline(result.result.profile.online)
                setApiKeyZoom(result.result.profile.zoom_api_key)
                setApiSecretZoom(result.result.profile.zoom_api_secret)
                setApiChatZoom(result.result.profile.zoom_im_chat_history_token)
                setValidatedZoom(result.result.profile.validatedZoom)
                setLoadedContent(result.result)
            }else{
                console.error('result.message')
            }
        })
    }

    const handletUpdateValue = (e,name) => {
        let value=e.target.value
        if(name == 'apiKey'){
            setApiKeyZoom(value)
        }
        if(name == 'apiSecret'){
            setApiSecretZoom(value)
        }
        if(name == 'apiChat'){
            setApiChatZoom(value)
        }
        setValidatedZoom(false)
    }

    let img
    let logo
    let specialtyidselected=null
    if(!loadedContent){
        fetchProfile()
    }else{
        if(loadedContent.profile.texture !== null){
            img=domains.domain+''+loadedContent.profile.texture
        }else{
            img=domains.domain+'img/user.png'
        }
        if(loadedContent.profile.logo !== null){
            logo=domains.domain+''+loadedContent.profile.logo
        }else{
            logo=domains.domain+'img/noimg_1.png'
        }
        if(loadedContent.specialty !== null){
            specialtyidselected=loadedContent.specialty.specialtiesid
        }
    }

    const validateZoom = () => {
        setLoadingValidation(true)
        let objecttosend = {key: apiKeyZoom, secret: apiSecretZoom, chat: apiChatZoom, profileid: profileid}
        objecttosend = btoa(JSON.stringify(objecttosend))
        let url = domains.domainapi + 'getToken/' + domains.apicode + '/' + objecttosend
        fetch(url).then(json => json.json()).then(result => {
            if (result.error === 0) {
                let token = result.result
                if(token.response !== undefined && token.response.code !== undefined){
                    setMessage(<div className={'alert alert-danger'}><Warning className={'mr-2'}/>{token.response.message}</div>)
                    setValidatedZoom(false)
                }else{
                    setMessage(<div className={'alert alert-success'}><Check className={'mr-2'}/>Los códigos del API han sido validados</div>);
                    setValidatedZoom(true)
                    setTimeout(()=>setMessage(false),2000)
                }
                setLoadingValidation(false)
            }
        })
    }

    const submitChanges = () => {
        setLoading(true)
        var formData = new FormData(document.querySelector('form#submitchanges'))
        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })

        let objecttosend={model:'CompanyProfile',id:loadedContent.profile.id}
        objecttosend=btoa(JSON.stringify(objecttosend))

        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url=domains.domainapi+'submitChanges/'+domains.apicode+'/'+objecttosend
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === 0){
                    window.location.href = '/editmedicalprofile/'+profileid
                }else{
                    console.log(json)
                }
            })

    }

    const handleChange = () => {
        if(online){
            setOnline(false)
        }else{
            setOnline(true)
        }
    }

    return (<div>
        {loadedContent ? <div className={'py-4'}>
            <div className={'text-center'}>
                <Title value={'Perfil de doctor'} className={'mb-4'}/>
            </div>
            <div className={'card card-body my-3'}>

                <form id={'submitchanges'} >
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 text-center'}>
                            <Subtitle value={'Foto del doctor'} />
                            <Thumb size={'250'} img={img}/>
                            <input name={'texture'} style={{display:'none'}} accept="image/*" id="icon-button-file" type="file" />
                            <label className={'mt-3'} htmlFor="icon-button-file">
                                <IconButton size={'large'} color="primary" aria-label="upload picture" component="div">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <hr/>
                            <Subtitle value={'Logo'} />
                            <Thumbsquare size={'250'} img={logo}/>
                            <input name={'logo'} style={{display:'none'}} accept="image/*" id="icon-button-file" type="file" />
                            <label className={'mt-3'} htmlFor="icon-button-file">
                                <IconButton size={'large'} color="primary" aria-label="upload picture" component="div">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </div>
                        <div className={'col'}>
                                <Input type={'text'} name={'alias'} defaultValue={loadedContent.profile.alias} className={'mb-0'} required={true} label={'Usuario'} readonly={true} />
                                <FormHelperText className={'mb-3'} id="my-helper-text">Este valor se utilizará como dirección para acceder a su perfil. Ej: intodoctor.com/{loadedContent.profile.alias}</FormHelperText>
                                <Input type={'text'} name={'name'} defaultValue={loadedContent.profile.name} className={'mb-3'} required={true} label={main.translations.name} />
                                <Input type={'text'} name={'doctorcountrynumber'} defaultValue={loadedContent.profile.doctorcountrynumber} className={'mb-3'} required={true} label={loadedContent.translations.tuition_number} />
                                <Input type={'text'} name={'whatsapp'} defaultValue={loadedContent.profile.whatsapp} className={'mb-3'} required={true} label={loadedContent.translations.cell_phone} />
                                <Select name={'countryid'} label={main.translations.select_country} className={'mb-3'} value={loadedContent.profile.countryid} data={loadedContent.countries} />
                                <Select name={'langid'} label={main.translations.select_language} className={'mb-3'} value={loadedContent.profile.langid} data={loadedContent.languages} />
                                <Select name={'main_specialty'} required={true} label={loadedContent.translations.principal_specialty} className={'mb-3'} value={specialtyidselected} data={loadedContent.specialties} />
                                <Tags name={'secondary_specialties'} value={loadedContent.specialties_selected} data={loadedContent.specialties} label={loadedContent.translations.secondary_specialty}/>
                                <FormControlLabel
                                    control={<Switch checked={online} onChange={handleChange} />}
                                    label={loadedContent.translations.this_account_offers_online_consulting}
                                />
                                <input name="online" type={'hidden'} value={online} />
                                {online ? <div className={'card card-body bg-light mb-3'}>
                                            <p>Hacemos las reuniones utilizando Zoom. Cada cuenta de doctor, debe de tener una cuenta Zoom creada y colocar los siguientes datos: </p>
                                            <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiKey')} name={'zoom_api_key'} defaultValue={apiKeyZoom} className={'mb-3 bg-white'} required={true} label={'Zoom API Key'} />
                                            <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiSecret')} name={'zoom_api_secret'} defaultValue={apiSecretZoom} className={'mb-3 bg-white'} required={true} label={'Zoom API Secret'} />
                                            <Input type={'text'} onChange={(e)=>handletUpdateValue(e,'apiChat')} name={'zoom_im_chat_history_token'} defaultValue={apiChatZoom} className={'mb-3 bg-white'} required={true} label={'Zoom IM chat history Token'} />
                                            <input name="validatedZoom" type={'hidden'} value={validatedZoom} />
                                            {loadingValidation ? <Loader/> : null}
                                            {message ? <div>{message}</div>:null}
                                            {!validatedZoom ? <div><p><small>Cada vez que haga un cambio a los datos del API de Zoom, debe validarlo clicando en el siguiente botón.</small></p>
                                            <ButtonRender size="large" color={'secondary'} onClick={()=>validateZoom()} variant={'contained'} label={main.translations.validate}/></div> :null}
                                        </div> : null }
                            {!loading ? <div>
                                {(online && validatedZoom) || !online ? <ButtonRender size="large" color={'primary'} onClick={()=>submitChanges()} variant={'contained'} label={main.translations.save}/> : <ButtonRender size="large" color={'primary'} onClick={()=>submitChanges()} disabled={true} variant={'contained'} label={main.translations.save}/>}
                            </div> : <Loader/>}
                        </div>
                    </div>
                </form>

            </div>
        </div> :<div className={'py-5 text-center'}><Loader/></div>
        }
        </div>
    )
}

export default Profile;
