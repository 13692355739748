import React, {useState} from "react";
import {toURI, Thumb, Title, Subtitle, Jumbotitle, Searcher} from './Generals'
function Home(props) {
    let domains=props.domains
    let main=props.main
    let title=main.translations.find_your_doctor
    if(main.franch.id !== 1){
        title+=' en '+main.franch.country.name
    }

    return (
        <div className='home'>
            <div className="bgcolor1 py-5 white">
                <div className="container py-5">
                    <Jumbotitle value={title} />
                    <div className="card card-body mt-3" style={{backgroundColor:'rgba(0,0,0,.1)'}}>
                        <Searcher showButton={true} size={'lg'} domains={domains} main={main}/>
                    </div>
                    <div className={'mt-3'}>{main.franch.content_home_trans}</div>
                </div>
            </div>
            <div className={'py-5'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <Title value={main.translations.resources} className={'mb-5 text-center'}/>
                        </div>
                        {main.resources.map(item=>{
                            let img
                            if(item.img !== null){
                                img=domains.domain+''+item.img
                            }else{
                                img=domains.domain+'img/noimg_1.png'
                            }
                            let namelink=main.translations.resources
                            let link=domains.domainlinkre+toURI(namelink)
                            let nametitle=toURI(item.title_translated)
                            link=link+"/"+nametitle+'-'+item.id
                            let shortdescription=item.description_translated.substring(0,100)
                            return(<div key={'resource'+item.id} className={'col-12 col-md-6 mb-5'}>
                                <div className="media">
                                    <Thumb className={'mr-4'} img={img} size={170}/>
                                    <div className="media-body">
                                        <a className={'color1'} href={link}><Subtitle classNAme={'mb-3'} value={item.title_translated}/></a>
                                        <div className={'mb-3'}>{shortdescription}...</div>
                                        <a className={'color1 mt-3'} href={link}>{main.translations.read_more}</a>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;
