import React, {useState} from "react";
import {Input, Loader, Select, DatePicker, Title, Thumb, ButtonRender, Subtitle, Moneyformat} from './Generals'
import * as Bootstrap from "react-bootstrap";

function MedicalPanel(props) {
    let domains=props.domains
    let main=props.main
    let session=domains.session
    let [loading,setLoading]=useState(false)
    let [filter,setFilter]=useState(null)
    const [loadedContent,setLoadedContent]=useState(false)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(0)
    const fetchProfile = () => {
        let objecttosend={userid:session.id,langid:domains.langid,filter:filter,page:page}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchMedicalPanelData/'+domains.apicode+'/'+objecttosend
        console.log(url)
        fetch(url).then(json=>json.text()).then(result=>{
            result=JSON.parse(result);
            if(result.error === 0){
                setLoadedContent(result.result)
                setPages(result.result.pages)
            }else{
                console.error(result.message)
            }
        })

    }
    if(!loadedContent){
        fetchProfile()
    }

    const validateAndGoIn = (profileid) => {
        let objecttosend={userid:session.id,langid:domains.langid,profileid:profileid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchUserPermission/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.text()).then(result=>{
            result=JSON.parse(result);
            if(result.error === 0 && result.result.error === 0){
                sessionStorage.setItem('Medical', JSON.stringify(result.result.result))
                window.location.href = '/medical';
            }else{
                console.error(result.message)
            }
        })
    }

    const filtering = (e) => {
        setFilter(e.target.value)
        setLoadedContent(false)
    }

    const changePage = (page) => {
        setPage(page)
        setPages(0)
        setLoadedContent(false)
    }


    let pagination = []
    if(pages > 0){
        for (let pagecount=1; pagecount <= pages; pagecount++){
            pagination.push(<Bootstrap.Pagination.Item onClick = {()=>changePage(pagecount)}key={pagecount} active={pagecount === page}>{pagecount}</Bootstrap.Pagination.Item>,)
        }
    }

    return (<div>
        {loadedContent ? <div className={'py-4 row justify-content-center'}>
            <div className={'col col-md-8 col-lg-8'}>
                <div className={'text-center'}>
                    <Title value={'Perfiles de Doctores asociados a tu cuenta.'} className={'mb-4'}/>
                    <input className={'form-control form-control-lg'} name={'filter'} onBlur={(e)=>filtering(e)} type={'text'} defaultValue={filter} placeholder={'Filtro'}/>
                </div>
                <div className={'row'}>
                {loadedContent.companies.map(item=>{
                    let img
                    if(item.company_profile.texture !== null){
                        img=domains.domain+''+item.company_profile.texture
                    }else{
                        img=domains.domain+'img/user.png'
                    }
                    return (<div className={'col-6'}><div className={'card card-body mt-4'}>
                        <div className="media">
                            <Thumb className={'mr-4'} img={img} size={100}/>
                            <div className="media-body">
                                <a href={item.company_profile.alias}>
                                    <Subtitle value={item.company_profile.name}/>
                                    {!item.company_profile.active ? <div className={'alert alert-warning py-1'}>Cuenta no verificada.</div> : null}
                                </a>
                                <p className="m-0 text-muted">
                                    {item.company_profile.company_specialties_profile !== undefined ?
                                        <div>
                                            {item.company_profile.company_specialties_profile.map((items,index)=>{
                                                console.log(items)
                                                return(<span><span dangerouslySetInnerHTML={{__html:items.company_specialty.nametranslated}}></span>
                                                    {index+1 < item.company_profile.company_specialties_profile.length ? <span>- </span>:null}</span>)
                                                }
                                            )}
                                        </div> : null}
                                </p>
                                <div>
                                    {item.company_users_type.generalconfig && item.company_profile.active ?
                                        <div>
                                            <ButtonRender onClick={()=>validateAndGoIn(item.company_profile.id)} className={'btn btn-info text-white mt-3'} label={'Entrar'} />
                                        </div>
                                        : null}
                                </div>

                            </div>
                        </div>
                    </div></div>)
                })}
                </div>
                {pages > 1 ? <div><Bootstrap.Pagination className={'mt-4'} size={'lg'}>{pagination}</Bootstrap.Pagination></div> : null}
                <div className={'text-center'}>
                <ButtonRender href={'/create-new'} className={'my-4'} size="large" color={'primary'} variant={'contained'} label={loadedContent.translations.add_new_medical_profile}/>
                </div>
            </div>
        </div> :<div className={'py-5 text-center'}><Loader/></div>
        }
        </div>
    )
}

export default MedicalPanel;
