import React from 'react';
import {Loader} from './Generals'

function Logout(){
    const sendLogout = () =>{
        sessionStorage.removeItem('Auth')
        sessionStorage.removeItem('Medical')

        window.location.href = '/?message=Sesión cerrada exitosamente.&typemsg=success';
    }

    setTimeout(sendLogout,500)
    return (
          <div className={'container py-5 text-center'}>
              <Loader />
          </div>
    )

}

export default Logout;
