import React, {useState} from "react";
import {DatePicker, Input, Loader, Select,Title} from './Generals'
function Register(props) {
    let domains=props.domains
    let main=props.main
    const [email,setEmail] = useState(null)
    const [password,setPassword] = useState(null)
    const [passwordd,setPasswordd] = useState(null)
    const [loading,setLoading] = useState(false)
    const [message,setMessage] = useState(null)
    const [isEmpty,setIsEmpty] = useState(null)
    const [error,setError] = useState({name:false,middlename:false})
    const validateLogin = (value) => {
        let formfield=new FormData(document.querySelector('form#register'))
        let errorvalue={}
        let fair=Array.from(formfield.entries())
        let missing = 0
        let process=fair.map(item=>{
            let id=item[0]
            let field=document.getElementById(id)
            let required=field.hasAttribute('required')
            let valu=item[1]
            if(valu === "" && required){
                errorvalue[id]=true
                missing++;
            }else{
                errorvalue[id]=false
            }
        })

        Promise.all(process).then(()=>{
            setError(errorvalue)
            if(missing == 0){
                if(password === passwordd){
                    setLoading(true)
                    setMessage(null)
                    let header = new Headers({
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'multipart/form-data'
                    });

                    let sentData={
                        method:'POST',
                        mode: 'cors',
                        header: header,
                        body:formfield
                    }

                    let objecttosend={langid:domains.langid}
                    objecttosend=btoa(JSON.stringify(objecttosend))
                    let url=domains.domainapi+'sendRegister/'+domains.apicode+'/'+objecttosend
                    fetch(url,sentData).then(json=>json.text()).then(
                        result=>{
                            result=JSON.parse(result)
                            if(result.result.error === 1){
                                errorvalue['username']=true
                                setMessage(result.result.message)
                                setLoading(false)
                                setError(errorvalue)
                                goTop()
                            }else{
                                let resultd=JSON.stringify(result.result.result)
                                sessionStorage.setItem('Auth', resultd)
                                window.location.href = '/';
                            }
                        })
                }else{
                    setMessage('Las contraseñas no coinciden.')
                    goTop()
                }
            }else{
                setMessage('Hay campos pendientes por llenar');
                goTop()
            }
        })

    }

    const goTop = () => {
        document.querySelector('#title').scrollIntoView({
            behavior: 'smooth'
        })
    }
    const [loadedContent,setLoadedContent]=useState(false)

    const fetchProfile = () => {
        let objecttosend={langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchRegisterData/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0){
                setLoadedContent(result.result)
            }else{
                console.error('result.message')
            }
        })

    }

    if(!loadedContent){
        fetchProfile()
    }

    return (
        <div className={'py-5 bggrey h-100'}>{loadedContent ?
            <div className="h-100">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-7 col-xs-12 h-100 table-cell text-center">
                            <Title id={'title'} value={loadedContent.translations.lets_get_started} />
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                            <form id={'register'}>
                                                {message !== null ? <div id={'message'} className={'alert alert-warning py-1 mt-2 validatespaceemail text-left'}><small>{message}</small></div> : null}
                                                <Input id={'name'} error={error.name} type={'text'} name={'name'} className={'mb-3'} required={true} label={main.translations.name} />
                                                <Input onChange={()=>null} id={'middlename'} error={error.middlename} type={'text'} name={'middlename'} className={'mb-3'} required={false} label={main.translations.middle_name} />
                                                <Input id={'lastname'} type={'text'} error={error.lastname} name={'lastname'} className={'mb-3'} required={true} label={main.translations.lastname} />
                                                <Input id={'username'} type={'text'} error={error.username} name={'username'} className={'mb-3'} required={true} label={main.translations.email} />
                                                <DatePicker id={'birthday'} error={error.birthday} name={'birthday'} label={main.translations.birthday} required={true} className={'mb-3'} />
                                                {isEmpty ? <div className={'validatespace alert alert-warning py-1 mt-2 validatespaceemail'}>Este campo no puede estar vacío.</div> : null}
                                                <Input id={'phone'} type={'text'} error={error.phone} name={'phone'} className={'mb-3'} required={true} label={main.translations.phone} />
                                                <Select required={true} id={'countryid'} name={'countryid'} error={error.countryid} label={main.translations.select_country} className={'mb-3'} data={loadedContent.countries} />
                                                <Select required={true} id={'langid'} name={'langid'} error={error.langid} label={main.translations.select_language} className={'mb-3'} data={loadedContent.languages} />
                                                <Select required={true} id={'maritalid'} name={'maritalid'} error={error.maritalid} label={main.translations.marital_status} className={'mb-3'} data={loadedContent.maritalstatuses} />
                                                <Select required={true} id={'sexid'} name={'sexid'} error={error.sexid} label={main.translations.sex} className={'mb-3'} data={loadedContent.sexs} />
                                                <Input id={'password'} type={'password'} onChange={(e)=>setPassword(e.target.value)} error={error.password} name={'password'} className={'mb-3'} required={true} label={main.translations.password} />
                                                <Input id={'passwordd'} type={'password'} onChange={(e)=>setPasswordd(e.target.value)} error={error.passwordd} name={'passwordd'} className={'mb-3'} required={true} label={main.translations.repeat_password} />
                                                {loading ? <Loader/> :
                                                    <div>
                                                        <p className="mb-0 text-center">
                                                            <button type={'button'} className="btn bgcolor1 btn-block text-white btn-lg" onClick={()=>validateLogin()}>{main.translations.create_account}</button>
                                                        </p>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null}
        </div>
    )
}

export default Register;
