import React, {useState} from "react";
import {
    Input,
    Loader,
    Select,
    DatePicker,
    Title,
    Thumb,
    ButtonRender,
    Subtitle,
    Moneyformat,
    AlertRender
} from './Generals'
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import * as Bootstrap from 'react-bootstrap'

function Locations(props) {
    let domains=props.domains
    let main=props.main
    let session=domains.session
    let medicalsession=domains.medicalsession
    const [loadedContent,setLoadedContent]=useState(false)
    const [sendingnew,setSendingNew]=useState(false)
    const [message,setMessage]=useState(null)

    const fetchProfile = () => {
        let objecttosend={profileid:medicalsession.company_profile.id,langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchMedicalLocation/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.text()).then(result=>{
            result=JSON.parse(result);
            if(result.error === 0){
                setLoadedContent(result.result)
            }else{
                console.error(result.message)
            }
        })

    }

    const submitNew = () => {
        setSendingNew(true)
        let objecttosend={profileid:medicalsession.company_profile.id,langid:domains.langid}
        var formData = new FormData(document.querySelector('form#new_location'))
        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })
        objecttosend=btoa(JSON.stringify(objecttosend))
        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url=domains.domainapi+'submitNewLocation/'+domains.apicode+'/'+objecttosend
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === 0){
                    handleClose()
                    setSendingNew(false)
                    setLoadedContent(false)
                    fetchProfile()
                }else{
                    console.log(json)
                }
            })
    }

    const deleteLocation = (id) => {
        let objecttosend={locationid:id}
        objecttosend=btoa(JSON.stringify(objecttosend))
        setLoadedContent(false)
        let url=domains.domainapi+'deleteLocation/'+domains.apicode+'/'+objecttosend
        fetch(url).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === 0){
                    setMessage('Consultorio borrado.')
                    fetchProfile()
                }else{
                    console.log(json)
                }
            })
    }

    if(!loadedContent){
        fetchProfile()
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <div>
        {loadedContent ?
            <div>

                <Subtitle className={'mt-4'} value={'Consultorios'}/>

                <Bootstrap.Button variant="primary" className={'my-4'} onClick={handleShow}>
                    Agregar nuevo
                </Bootstrap.Button>
                {message !== null ? <div className={'mb-3'}><AlertRender type={'success'} value={message} /></div> : null}

                <Bootstrap.Modal show={show} onHide={handleClose}>
                    <Bootstrap.Modal.Header closeButton>
                        <Bootstrap.Modal.Title>Agregando nuevo consultorio</Bootstrap.Modal.Title>
                    </Bootstrap.Modal.Header>
                    <Bootstrap.Modal.Body>
                        <form id={'new_location'}>
                            <Input className={'mb-3'} label={'Referencia'} placeholder={'Ej: Piso 4, Local 201'} name={'detalle'}/>
                            <Input className={'mb-3'} label={main.translations.phone} name={'phone'}/>
                            <Select name={'placeid'} label={'Establecimiento'} className={'mb-3'} data={loadedContent.establishments} />
                            <input type={'hidden'} name={'countryid'} value={medicalsession.company_profile.countries_franquicia.countryid}/>
                        </form>
                    </Bootstrap.Modal.Body>
                    <Bootstrap.Modal.Footer>
                        {!sendingnew ?
                            <Bootstrap.Button variant="primary" onClick={submitNew}>
                               Guardar
                            </Bootstrap.Button>
                        : <Loader/>}
                    </Bootstrap.Modal.Footer>
                </Bootstrap.Modal>
                {loadedContent.consultorios.length > 0 ?
                    <div>
                        <Bootstrap.Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Referencia</th>
                                <th>Teléfono</th>
                                <th>Establecimiento</th>
                                <th>Accion</th>
                            </tr>
                            </thead>

                            <tbody>
                            {loadedContent.consultorios.map(item => {
                                return (<tr>
                                    <td>{item.id}</td>
                                    <td>
                                        <Input defaultValue={item.detalle} className={'bg-white'} label={'Referencia'}
                                               placeholder={'Eje: Piso 3, Consultorio 201'}/>
                                    </td>
                                    <td>
                                        <Input defaultValue={item.phone} className={'bg-white'} label={'Teléfono'}/>
                                    </td>
                                    <td>
                                        <Select name={'placeid'} label={'Establecimiento'} className={'mb-3'}
                                                value={item.company_locations_establishment.id}
                                                data={loadedContent.establishments}/>
                                    </td>
                                    <td>
                                        <button onClick={()=>deleteLocation(item.id)} className={'btn btn-primary btn-sm ml-2'}><Delete/></button>
                                    </td>
                                </tr>)
                            })
                            }

                            </tbody>
                        </Bootstrap.Table>
                    </div>
                :null}
            </div>
            : <Loader/>}
    </div>
}

export default Locations;
