import React, {useState} from "react";
import {Loader} from './Generals'
function Perfil(props) {
    let domains=props.domains
    let main=props.main
    const [email,setEmail] = useState(null)
    const [password,setPassword] = useState(null)
    const [loading,setLoading] = useState(false)
    const [message,setMessage] = useState(null)
    const [isEmpty,setIsEmpty] = useState(null)
    const [isEmptyPass,setIsEmptyPass] = useState(null)
    const sendLogin = (values) => {
        let field
        values=btoa(JSON.stringify(values))
        let url=domains.domainapi+'sendLogIn/'+domains.apicode+'/'+values
        fetch(url,{
            credentials: 'include'
        }).then(response=>response.json()
        ).then((json)=>{
            if(json.result.result.state === "success"){
                let result=JSON.stringify(json.result.result.details)
                sessionStorage.setItem('Auth', result)
                window.location.href = '/';
            }else{
                setLoading(false)
                setMessage(json.result.result.message)
            }

        })
    }

    const validateLogin = (value) => {
        let missing = 0
        if(email === "" || email === null){
            setIsEmpty(true)
            missing++
        }else{
            setIsEmpty(false)
        }
        if(password === "" || password === null){
            setIsEmptyPass(true)
            missing++
        }else{
            setIsEmptyPass(false)
        }
        if(missing === 0){
            setLoading(true)
            let values= {
                email:email,
                pass:password
            }
            sendLogin(values)
        }

    }


    return (
        <div className={'py-5 bggrey h-100'}>
            <div className="h-100">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center my-5">
                        <div className="col-lg-6 col-md-6 col-sm-7 col-xs-12 h-100 table-cell text-center">
                            <div className="card">
                                <div className="card-body text-center">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                {message !== null ? <div className={'alert alert-warning py-1 mt-2 validatespaceemail'}>{message}</div> : null}
                                                <label>{main.translations.username}<i
                                                    className="fa fa-asterisk red"></i></label>
                                                <input onChange={(e)=>setEmail(e.target.value)}
                                                    className="form-control form-control-lg input-lg margin-bottom-small"
                                                    type="text" required="required" name="username" id="username"/>
                                                {isEmpty ? <div className={'validatespace alert alert-warning py-1 mt-2 validatespaceemail'}>Este campo no puede estar vacío.</div> : null}
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                <label>{main.translations.password} <i
                                                    className="fa fa-asterisk red"></i></label>
                                                <input onChange={(e)=>setPassword(e.target.value)}
                                                    className="form-control form-control-lg input-lg margin-bottom-small"
                                                    type="password" required="required" id="password" name="password"/>
                                                {isEmptyPass ? <div className={'validatespace alert alert-warning py-1 mt-2 validatespacepassword'}>Este campo no puede estar vacío.</div> : null}

                                            </div>

                                        </div>
                                    {loading ? <Loader/> :
                                        <div>
                                            <button onClick={()=>{validateLogin()}} type={'button'}
                                               className="btn inline-block bgcolor1 text-white btn-block btn-lg input-lg margin-top-small"
                                            >{main.translations.log_in}</button>

                                            <p className="mt-3 text-center"><a className="color1"
                                                                               href="/reset">{main.translations.forgot_password}</a>
                                            </p>
                                            <div className="separatord mt-4">
                                                <span className="text-lowercase">{main.translations.or_you_can}</span>
                                            </div>
                                            <p className="margin-top-small mb-0 text-center"><a
                                                className="btn bgcolor2 btn-block text-white btn-lg"
                                                href={'/'+main.translations.create_account_alias}>{main.translations.create_account}</a>
                                            </p>
                                        </div>
                                         }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Perfil;
