import React, {useState} from "react";
import {Input,Loader,Select,DatePicker,Title,Thumb,ButtonRender} from './Generals'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

function Profile(props) {
    let domains=props.domains
    let main=props.main
    let session=domains.session
    let [loading,setLoading]=useState(false)
    const [loadedContent,setLoadedContent]=useState(false)

    const fetchProfile = () => {
        let objecttosend={langid:domains.langid}
        objecttosend=btoa(JSON.stringify(objecttosend))
        let url=domains.domainapi+'fetchEditProfileData/'+domains.apicode+'/'+objecttosend
        fetch(url).then(json=>json.json()).then(result=>{
            if(result.error === 0){
                setLoadedContent(result.result)
            }else{
                console.error('result.message')
            }
        })

    }
    let img
    if(!loadedContent){
        fetchProfile()
    }else{
        if(session.img !== null){
            img=domains.domain+''+session.img
        }else{
            img=domains.domain+'img/user.png'
        }
    }

    const submitChanges = () => {
        setLoading(true)
        var formData = new FormData(document.querySelector('form#submitchanges'))
        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })

        let objecttosend={model:'Users',id:session.id}
        objecttosend=btoa(JSON.stringify(objecttosend))

        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url=domains.domainapi+'submitChanges/'+domains.apicode+'/'+objecttosend
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === 0){
                    let result=JSON.stringify(json.result.item)
                    sessionStorage.setItem('Auth', result)
                    window.location.href = '/profile?message=Se han guardado los cambios.';
                }else{
                    console.log(json)
                }
            })

    }


    return (<div>
        {loadedContent ? <div className={'py-4'}>
            <div className={'card card-body'}>
                <Title value={'Mi perfil'} className={'mb-4'}/>
                <form id={'submitchanges'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 text-center'}>
                            <Thumb size={'250'} img={img}/>
                            <input name={'img'} style={{display:'none'}} accept="image/*" id="icon-button-file" type="file" />
                            <label className={'mt-3'} htmlFor="icon-button-file">
                                <IconButton size={'large'} color="primary" aria-label="upload picture" component="div">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </div>
                        <div className={'col'}>
                                <Input type={'text'} name={'name'} defaultValue={session.name} className={'mb-3'} required={true} label={main.translations.name} />
                                <Input type={'text'} name={'middlename'} defaultValue={session.middlename} className={'mb-3'} required={true} label={main.translations.middle_name} />
                                <Input type={'text'} name={'lastname'} defaultValue={session.lastname} className={'mb-3'} required={true} label={main.translations.lastname} />
                                <Input type={'text'} name={'phone'} defaultValue={session.phone} className={'mb-3'} required={true} label={main.translations.phone} />
                                <DatePicker name={'birthday'} label={main.translations.birthday} defaultValue={session.birthday} className={'mb-3'} />
                                <Select required={true} name={'countryid'} label={main.translations.select_country} className={'mb-3'} value={session.countryid} data={loadedContent.countries} />
                                <Select required={true} name={'langid'} label={main.translations.select_language} className={'mb-3'} value={session.langid} data={loadedContent.languages} />
                                <Select required={true} name={'maritalid'} label={main.translations.marital_status} className={'mb-3'} value={session.maritalid} data={loadedContent.maritalstatuses} />
                                {!loading ? <ButtonRender size="large" color={'primary'} onClick={()=>submitChanges()} variant={'contained'} label={main.translations.save}/> : <Loader/>}
                        </div>
                    </div>
                </form>

            </div>
        </div> :<div className={'py-5 text-center'}><Loader/></div>
        }
        </div>
    )
}

export default Profile;
